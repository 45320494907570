import React from 'react'
// import PropTypes from 'prop-types'
import { navigate } from "gatsby"
import { globalHistory } from '@reach/router'
import { Helmet } from "react-helmet"

import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

import isBrowser from "utils/isBrowser"
import objToQueryString from "utils/objToQueryString/objToQueryString";
import parseUrlQuery from "utils/parseUrlQuery/parseUrlQuery";
import DataVizNetwork from "./dataVizNetwork/DataVizNetwork";
// import RevolvingDoorContainer from "./revolvingDoorContainer/RevolvingDoorContainer.js";
// import LobbyingMap from "./lobbyingMap/LobbyingMap.js";
import IndustryLevelAnalysis from "./industryLevelAnalysis/IndustryLevelAnalysis";
// import RelationalClients from "./relationalClients/RelationalClients";
import { NAVBAR_HEIGHT } from "components/navbar/Navbar"

class DataVizPage extends React.Component {
  constructor(props) {
    super(props)

    this.VIZ_KEYS = {
      NETWORK: "network",
      // REVOLVING_DOOR: "revolvingDoor",
      // LOBBYING_MAP: "lobbyingMap",
      INDUSTRY_LEVEL_ANALYSIS: "industryLevelAnalysis",
      // RELATIONAL_CLIENTS: "relationalClients"
    }

    this.state = {
      tab: "",
      height: 300,
      pageHeight: 500,
      width: 1000,
    };

    this.boxRef = React.createRef();
  }

  componentDidMount() {
    const query = parseUrlQuery(this.props.location.search.substring(1)); //get the query in the url if any
    query.vizTab = query.vizTab || this.VIZ_KEYS.NETWORK;
    navigate(this.props.location.pathname+"?"+objToQueryString(query)); //set the url
    this.setState({tab: query.vizTab})

    window.addEventListener("resize", this.resize); //add resize listener
    this.resize();

    this.stopListening = globalHistory.listen(() => { //set event listener to change viz tabs
      const query = parseUrlQuery(window.location.search.substring(1)); //get the query in the url if any
      if(typeof query.vizTab === "string") { //if the viz tab is valid
        this.setState({ tab: query.vizTab }); //change tabs
      }
    })
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize); //remove resize listener
    this.stopListening() //stop listening to the history when the component unmounts
  }

  resize = e => {
    if(this.boxRef.current) {
      this.setState({
        width: this.boxRef.current.clientWidth,
        height: this.boxRef.current.clientHeight,
        pageHeight: window.innerHeight - NAVBAR_HEIGHT,
      });
    }
  }

  getContent = () => {
    if(this.state.tab === this.VIZ_KEYS.NETWORK) {
      return (
        <DataVizNetwork
          pageHeight={this.state.pageHeight}
          tab={this.VIZ_KEYS.NETWORK}
        />
      )
    }
    // else if(this.state.tab === this.VIZ_KEYS.REVOLVING_DOOR) {
    //   return (
    //     <RevolvingDoorContainer
    //       width={this.state.width}
    //       height={this.state.height}
    //       revolvingDoorData={this.props.revolvingDoorData}
    //       tab={this.VIZ_KEYS.REVOLVING_DOOR}
    //     />
    //   )
    // }
    // else if(this.state.tab === this.VIZ_KEYS.LOBBYING_MAP) {
    //   return (
    //     <LobbyingMap
    //       width={this.state.width}
    //       height={this.state.height}
    //       tab={this.VIZ_KEYS.LOBBYING_MAP}
    //     />
    //   )
    // }
    else if(this.state.tab === this.VIZ_KEYS.INDUSTRY_LEVEL_ANALYSIS) {
      return (
          <IndustryLevelAnalysis width={this.state.width} height={this.state.height} client={this.props.client} tab={this.VIZ_KEYS.INDUSTRY_LEVEL_ANALYSIS} />
      )
    }
    // else if(this.state.tab === this.VIZ_KEYS.RELATIONAL_CLIENTS) {
    //   return (
    //       <RelationalClients
    //       width={this.state.width}
    //       height={this.state.height}
    //       tab={this.VIZ_KEYS.RELATIONAL_CLIENTS}/>
    //   )
    // }
  }

  render() {
    // //if the user clicked on the network tab AND there is no network data yet
    // if(vizName===this.VIZ_KEYS.network && this.state.networkData.nodes.length===0) {
    //   console.log("we are getting new network data");
    //   this.getNetworkData(query);
    // }
    //
    // networkData={this.state.networkData}
    // networkRequestStatus={this.state.networkRequestStatus}

    return (
      <div style={{height: this.state.pageHeight, padding: "1em"}}>
        <Helmet>
          <script type="text/javascript" async src="https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-svg.js"/>
        </Helmet>

        <div style={{width: "100%", height: "calc(100% - 5px)"}} ref={this.boxRef}>
          <div>
            <Tabs activeKey={this.state.tab} onSelect={ eventKey => {
              const pathname = isBrowser ? window.location.pathname : ""
              const queryStr = isBrowser ? window.location.search.substring(1) : ""

              const query = parseUrlQuery(queryStr); //get the query in the url if any
              query.vizTab = eventKey; //set the vizTab query in the query object
              navigate(pathname+"?"+objToQueryString(query)); //change the URL (which will change the tab in the history listener)
            }}
            >
            <Tab eventKey={this.VIZ_KEYS.NETWORK} title="Network"></Tab>
            {/* <Tab eventKey={this.VIZ_KEYS.REVOLVING_DOOR} title="Revolving Door"></Tab> */}
            {/* <Tab eventKey={this.VIZ_KEYS.LOBBYING_MAP} title="Lobbying Map"></Tab> */}
            <Tab eventKey={this.VIZ_KEYS.INDUSTRY_LEVEL_ANALYSIS} title="Industry Level Analysis"></Tab>
            {/* <Tab eventKey={this.VIZ_KEYS.RELATIONAL_CLIENTS} title="Relational Clients"></Tab> */}
            </Tabs>
          </div>

          <div style={{height: "calc(100% - 45px)"}}>
            {this.getContent()}
          </div>
        </div>
      </div>
    )
  }
}

DataVizPage.propTypes = {
}

export default DataVizPage
