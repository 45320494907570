import { interpolateHsl, range, scaleLinear } from "d3";
import comparator from 'object-comparator' //https://github.com/harryli0088/object-comparator

import { binarySearch } from "utils/binaryInsert/binaryInsert";

type EdgeType = {
  edge_id: number,
  node_ids: [number, number], //[politician node id, company node id]
  entity_details: {
    n_issues: number,
  }
}

type NodeType = {
  node_id: number,
  entity_name: string,
  entity_details: {
    last_name?: string,
    first_name?: string,
    politician_id?: string,
    client_uuid?: string,
  }
}

type NetworkDataType = {
  edges: EdgeType[],
  nodes: NodeType[],
}


type MatrixCellType = {
  c: number,
  r: number,
  z: number,
}

type HeadingType = {
  name: string,
  node_id: number,
  node: NodeType,
  count: number,
}

/**
 * given the network data from the server
 * process and return the data necessary for the visualization
 * @param  networkData from the server
 * @return             object containing fields: colorFunction, columns, matrix, maxContributionCount, rows
 */
export default function processNetworkData(
  networkData: NetworkDataType,
) {
  const makeHeadingFromNode = (n: NodeType):HeadingType => ({ //given a node, return a heading type object
    name: n.entity_name,
    node_id: n.node_id,
    node: n,
    count: 0,
  });
  const matrix: MatrixCellType[][] = []; //initialize empty matrix
  let maxContributionCount:number = 0; //used to track the maximum value from an edge
  const nodeIdComparator = comparator([{field:"node_id"}]); //used to sort an array by node_id



  //make the politician and company headings from the nodes
  const companies:HeadingType[] = networkData.nodes.filter(
    n => n.entity_details.politician_id === undefined //filter the nodes that do NOT have a politician id
  ).map(makeHeadingFromNode).sort(nodeIdComparator);

  const politicians:HeadingType[] = networkData.nodes.filter(
    n => n.entity_details.politician_id !== undefined //filter the nodes that have a politician id
  ).map(makeHeadingFromNode).sort(nodeIdComparator);

  //initialize zero value matrix with companies as rows and politicians as columns
  for(let i=0; i<companies.length; ++i) {
    matrix[i] = range(politicians.length).map(j => ({r: i, c: j, z: 0}));
  }



  //convert contributions to matrix; track contributions
  networkData.edges.forEach(e => {
    const polIndex = binarySearch(
      politicians,
      {node_id:e.node_ids[0]},
      nodeIdComparator
    ); //get politician index
    const companyIndex = binarySearch(
      companies,
      {node_id:e.node_ids[1]},
      nodeIdComparator
    ); //get company index
    matrix[companyIndex][polIndex].z += e.entity_details.n_issues; //increment by the number of contributions
    companies[companyIndex].count += e.entity_details.n_issues; //increment contributions count
    politicians[polIndex].count += e.entity_details.n_issues; //increment contributions count
    maxContributionCount = Math.max(matrix[companyIndex][polIndex].z,maxContributionCount); //track the maximum number of contributions between one company and one politician
  });

  //@ts-ignore, typescript complains but this is fine
  const color = scaleLinear().domain([0, maxContributionCount]).range(['lightblue', 'blue']).interpolate(interpolateHsl);
  const colorFunction = function(d: number) {
    if(d <= 0) return "white";
    return color(d);
  }



  return {
    colorFunction,
    columns: politicians,
    matrix,
    maxContributionCount,
    rows: companies,
  };
}
