import gql from 'graphql-tag';

/**
 * given an issue code and year, return the network query
 * @param  {String} issueCode issue code, ex "TAX"
 * @param  {Number} year      ex 2014
 * @return {Graphql Query}
 */
export default function getNetworkQuery(issueCode, year) {
  return (
    gql`
    {
      vizTop50FirmsTop50Politicians(
        _issueTopicOfInterest: "${issueCode}",
        _yearOfInterest: ${year}
      )
    }
    `
  )
}
