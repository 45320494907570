import React from 'react';
import { withApolloClient } from "utils/graphqlSetup";
import gql from "graphql-tag";
import * as d3 from "d3";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { navigate } from "gatsby"
import { globalHistory } from '@reach/router'

import "./IndustryLevelAnalysis.scss";
import exampleJson from "./example.json";

import SocialMedia from "components/socialMedia/SocialMedia"
import AutoSuggestion from "components/autoSuggestion/AutoSuggestion";

import setDocumentTitle from "utils/setDocumentTitle/setDocumentTitle";
import parseUrlQuery from "utils/parseUrlQuery/parseUrlQuery";
import formatMoney from "../../../utils/formatMoney/formatMoney";
import AUTO_SUGGEST_YEAR from "utils/graphql/autoSuggest/year/year";
import AUTO_SUGGEST_SIG_NAICS from "utils/graphql/autoSuggest/sigNaics/sigNaics";

const deepClone = (obj) => {

  if (obj === null || typeof obj !== 'object') return obj;

  const result = Array.isArray(obj) ? [] : {};

  for (const key of Object.keys(obj)) result[key] = deepClone(obj[key]);

  return result;

};

class IndustryLevelAnalysis extends React.Component {

  constructor(props) {
    super(props);
    setDocumentTitle("Industry Level Analysis");

    // example data
    this.state = {
      //searching
      year: null,
      naics: null,
      naicsTitle: '',

      data: null,

      //ui display
      displayYear: null,
      displayNaics: null,
      displayNaicsTitle: null,

      totalAmount: 0,
      topClients: [],
      topLegislators: []
    };

    this.yearSuggestions = {
      getQuery: AUTO_SUGGEST_YEAR,
      processResponse: response => {
        if (!response.data || !response.data.topLobbyingYear) { throw Error("Response error"); }
        return response.data.topLobbyingYear;
      },
      getSuggestionString: suggestion => { return suggestion.lobbyingYear.toString(); },
      clickSuggestion: (self, suggestion) => {
        this.setState({
          year: suggestion.lobbyingYear,
        });
        self.clearSuggestions(); //clear the suggestions
      }
    };

    this.naicsSuggestions = {
      getQuery: AUTO_SUGGEST_SIG_NAICS,
      processResponse: response => {
        if (!response.data || (!response.data.topNaics)) { throw Error("Response error"); }
        return response.data.topNaics;
      },
      getSuggestionString: suggestion => suggestion.naics.naics + " " + suggestion.naics.naicsTitle,
      clickSuggestion: (self, suggestion) => {
        this.setState({
          naics: suggestion.naics.naics,
          naicsTitle: suggestion.naics.naicsTitle
        });
        self.clearSuggestions(); //clear the suggestions
      }
    };

  }

  componentDidMount() {
    this.updateData(window.location);

    // url change listener
    this.stopListening = globalHistory.listen(() => this.updateData(window.location));

    // resize listener
    window.addEventListener("resize", this.resizeGraph);
  }

  componentWillUnmount() {
    this.stopListening()

    // resize listener
    window.removeEventListener("resize", this.resizeGraph);
  }

  resizeGraph = () => {

    this.clearGraph();
    this.drawGraph();

  }

  search = () => {

    if (this.state.naics === null || this.state.year === null) {
      alert("Both Year and NAICS Code should be selected!");
      return;
    }

    this.clearGraph();

    this.setState({
      totalAmount: 0,
      topClients: [],
      topLegislators: []
    });

    // update url
    navigate(`?vizTab=industryLevelAnalysis&year=${this.state.year}&naics=${this.state.naics}`);

  }

  updateData = (location) => {

    const urlQuery = parseUrlQuery(location.search.substring(1));

    if (urlQuery.year !== undefined && urlQuery.naics !== undefined) { // if url has year and nacis

      this.setState({
        year: urlQuery.year,
        naics: urlQuery.naics
      }, () => {

        this.setState({
          displayYear: this.state.year,
          displayNaics: this.state.naics,
          displayNaicsTitle: this.state.naicsTitle
        });

        this.getData(this.state.naics, this.state.year)
          .then(() => {
            if (this.state.data !== null) {
              this.drawGraph();
              this.loadOverview();
            }
          })
          .catch((error) => {
            console.log(error);
            alert('An unexpected error occurred!');
          });

      });

    } else if(!this.state.displayYear) { // else if we haven't already loaded example data data

      //load example data
      this.setState({
        displayYear: '2015',
        displayNaics: 'Example Industry',
        displayNaicsTitle: '(chosen by NAICS)',
        data: exampleJson.data.vizFirmToPolitician
      }, () => {

        this.drawGraph();
        this.loadOverview();

      });

    }

  }

  getData = async (naicsCode, year) => {

    const queryResult = await this.props.client.query({
      query: gql`
                query FirmToPoliticianQuery {
                  vizFirmToPolitician(naicsCode: "${naicsCode}", year: ${year}) {
                    total
                    perClient
                    perLegislator
                    perClientPerLegislator
                  }
                }
            `});

    this.setState({
      data: queryResult.data.vizFirmToPolitician
    }, () => {

      if (this.state.data === null) alert("Selected Year and NAICS Code has no data!");

    });

  }

  clearGraph = () => document.getElementById('svgContainer').innerHTML = '';

  drawGraph = () => {

    // PROCESS DATA

    const COLOR_DEMO = 'blue';
    const COLOR_REP = 'red';
    const COLOR_INDEPENDENT = 'back';

    const amountPerClient = {};
    const crustData = [];
    const emptySpace = { party: null, amount: (this.state.data.total / 100), client: null, legislator: null };

    for (const item of this.state.data.perClient) {

      // add amount to client data
      if (!(item.client in amountPerClient)) amountPerClient[item.client] = 0;
      amountPerClient[item.client] += item.amount;

    }

    const compare = (a, b) => {

      if (amountPerClient[a.client] < amountPerClient[b.client]) return 1;
      else if (amountPerClient[a.client] > amountPerClient[b.client]) return -1;
      else {

        if (a.party < b.party) return -1;
        else if (a.party > b.party) return 1;
        else {

          if (a.amount < b.amount) return 1;
          else if (a.amount > b.amount) return -1;
          else return 0;

        }

      }

    };

    const sortedPerClientPerLegislator = this.state.data.perClientPerLegislator.sort(compare);
    let lastClient = null;

    for (const item of sortedPerClientPerLegislator) {

      // add empty space between companies
      if (item.client !== lastClient) {
        crustData.push(emptySpace);
        lastClient = item.client;
      }

      crustData.push(item);

    }

    const demoList = [];
    const repList = [];
    const independentList = [];

    for (const item of this.state.data.perLegislator) {

      if (item.party === 'Democrat') demoList.push(item);
      else if (item.party === 'Republican') repList.push(item);
      else if (item.party === 'Independent') independentList.push(item);

    }

    // D3 INIT

    let CANVAS_PADDING = 90;
    let descriptionHeight = 0;
    if (this.state.displayNaics === 'Example Industry') {
      const descriptionElement = document.getElementById('industryLevelAnalysisDescription');
      descriptionHeight = descriptionElement.getBoundingClientRect().height + 30;
      CANVAS_PADDING = 50;
    }

    const svgContainerElement = document.getElementById('svgContainer');
    const CONTAINER_LEFT = svgContainerElement.getBoundingClientRect().left;
    const CONTAINER_TOP = svgContainerElement.getBoundingClientRect().top;
    const CANVAS_WIDTH = svgContainerElement.parentElement.clientWidth;
    const CANVAS_HEIGHT = svgContainerElement.parentElement.clientHeight - descriptionHeight;

    const BUBBLE_PADDING = 10;
    const GRAPH_DIAMETER = (CANVAS_WIDTH > CANVAS_HEIGHT ? CANVAS_HEIGHT : CANVAS_WIDTH) - CANVAS_PADDING * 2;

    const svgContainer = d3.select('#svgContainer');

    const svg = svgContainer
      .append('svg')
      .attr('width', CANVAS_WIDTH)
      .attr('height', CANVAS_HEIGHT);

    // CLIENT CRUST

    const radius = Math.min(GRAPH_DIAMETER, GRAPH_DIAMETER) / 2;

    const pie = d3
      .pie()
      .sort(null)
      .value((d) => d.amount)

    const arc = d3
      .arc()
      .innerRadius(radius)
      .outerRadius(radius + 15);

    const outerArc = d3
      .arc()
      .innerRadius(radius + 30)
      .outerRadius(radius + 31);

    const arcData = pie(crustData);

    // LEGISLATOR BUBBLES

    const legislatorData = {
      'children': [
        { 'children': demoList },
        { 'children': repList },
        { 'children': independentList }
      ]
    };

    const pack = d3
      .pack()
      .size([GRAPH_DIAMETER - BUBBLE_PADDING * 2, GRAPH_DIAMETER - BUBBLE_PADDING * 2])
      .padding(10);

    const root = d3
      .hierarchy(legislatorData)
      .sum((d) => d.amount);

    pack(root);

    // CONNECTIONS

    const legislatorInfo = {};

    for (const item of root.descendants()) {
      if (item.data.legislator !== undefined) {

        legislatorInfo[item.data.legislator] = {
          x: item.x,
          y: item.y
        };

      }
    }

    const line = d3
      .line()
      .x((d) => d.x)
      .y((d) => d.y)
      .curve(d3.curveLinear);

    const connection = (d) => {

      const points = [];

      for (let i = d.startAngle; i < d.endAngle; i += 0.05) {
        points.push({
          x: Math.sin(i) * (GRAPH_DIAMETER / 2) + CANVAS_WIDTH / 2,
          y: (1 - Math.cos(i)) * (GRAPH_DIAMETER / 2) + CANVAS_HEIGHT / 2 - GRAPH_DIAMETER / 2
        });
      }

      points.push({
        x: Math.sin(d.endAngle) * (GRAPH_DIAMETER / 2) + CANVAS_WIDTH / 2,
        y: (1 - Math.cos(d.endAngle)) * (GRAPH_DIAMETER / 2) + CANVAS_HEIGHT / 2 - GRAPH_DIAMETER / 2
      });

      points.push({
        x: legislatorInfo[d.data.legislator].x + CANVAS_WIDTH / 2 - GRAPH_DIAMETER / 2 + BUBBLE_PADDING,
        y: legislatorInfo[d.data.legislator].y + CANVAS_HEIGHT / 2 - GRAPH_DIAMETER / 2 + BUBBLE_PADDING
      });

      return points;

    };

    // TOOLTIP

    const showCrustTooltip = (client, legislator) => {

      for (const item of arcData) {

        if (item.data.client === client && item.data.legislator === legislator) {

          const middleAngle = (item.startAngle + item.endAngle) / 2;

          const EXTRA_PADDING = 30;
          let x = Math.sin(middleAngle) * ((GRAPH_DIAMETER + EXTRA_PADDING) / 2) + CANVAS_WIDTH / 2 + CONTAINER_LEFT;
          let y = (1 - Math.cos(middleAngle)) * ((GRAPH_DIAMETER + EXTRA_PADDING) / 2) + CANVAS_HEIGHT / 2 - GRAPH_DIAMETER / 2 - EXTRA_PADDING / 2 + CONTAINER_TOP;

          const tooltip = d3
            .select('body')
            .append('div')
            .attr('class', 'industryLevelAnalysisTooltip')
            .style('left', 0)
            .style('top', 0)
            .html(() => `${client} <br> Total : $${formatMoney(amountPerClient[client])} <br><br> ${legislator} <br> Amount : $${formatMoney(item.data.amount)}`);

          // adjust tooltip positions to outer direction
          const width = Number(tooltip.style('width').replace('px', ''));
          const height = Number(tooltip.style('height').replace('px', ''));

          if (middleAngle <= Math.PI / 8) { x -= width / 2; y -= height; } // top
          else if (Math.PI / 8 < middleAngle && middleAngle <= 3 * Math.PI / 8) { y -= height; }
          else if (3 * Math.PI / 8 < middleAngle && middleAngle <= 5 * Math.PI / 8) { y -= height / 2; } // right
          else if (5 * Math.PI / 8 < middleAngle && middleAngle <= 7 * Math.PI / 8) { }
          else if (7 * Math.PI / 8 < middleAngle && middleAngle <= 9 * Math.PI / 8) { x -= width / 2; } // bottom
          else if (9 * Math.PI / 8 < middleAngle && middleAngle <= 11 * Math.PI / 8) { x -= width; }
          else if (11 * Math.PI / 8 < middleAngle && middleAngle <= 13 * Math.PI / 8) { x -= width; y -= height / 2; } // left
          else if (13 * Math.PI / 8 < middleAngle && middleAngle <= 15 * Math.PI / 8) { x -= width; y -= height; }
          else if (15 * Math.PI / 8 < middleAngle) { x -= width / 2; y -= height; } // top

          tooltip
            .style('left', x + 'px')
            .style('top', y + 'px');

          break;

        }

      }

    };

    const showLegislatorTooltip = (legislator) => {

      for (const item of root.descendants()) {

        if (item.data.legislator === legislator) {

          const EXTRA_PADDING = 10;
          const x = item.x + CANVAS_WIDTH / 2 - GRAPH_DIAMETER / 2 + BUBBLE_PADDING + CONTAINER_LEFT + EXTRA_PADDING;
          const y = item.y + CANVAS_HEIGHT / 2 - GRAPH_DIAMETER / 2 + BUBBLE_PADDING + CONTAINER_TOP + EXTRA_PADDING;

          d3
            .select('body')
            .append('div')
            .attr('class', 'industryLevelAnalysisTooltip')
            .style('left', x + 'px')
            .style('top', y + 'px')
            .html(() => `${legislator} <br> Amount : $${formatMoney(item.data.amount)}`);

          break;

        }

      }

    };

    // MOUSE FUNCTIONS

    const mouseOverLegislator = (legislator) => {

      d3
        .selectAll('.connections')
        .data(arcData.filter(it => it.data.legislator !== null))
        .attr('opacity', (d) => {
          if (d.data.legislator === legislator) return 0.7;
          else return 0.1;
        });

      d3
        .selectAll('.bubbles')
        .data(root.descendants())
        .attr('opacity', (d) => {
          if (d.data.party === undefined) return 0;
          else if (d.data.legislator === legislator) return 0.7;
          else return 0.3;
        });

      d3
        .selectAll('.outerCrust')
        .attr('opacity', 0);

      d3
        .selectAll('.outerCrustText')
        .attr('opacity', 0);

      for (const item of arcData) {

        if (item.data.legislator === legislator) showCrustTooltip(item.data.client, item.data.legislator);

      }

      showLegislatorTooltip(legislator);

    };

    const mouseOverCrust = (client, legislator) => {

      d3
        .selectAll('.connections')
        .data(arcData.filter(it => it.data.legislator !== null))
        .attr('opacity', (d) => {
          if (d.data.legislator === legislator && d.data.client === client) return 0.7;
          else return 0.1;
        });

      d3
        .selectAll('.bubbles')
        .data(root.descendants())
        .attr('opacity', (d) => {
          if (d.data.party === undefined) return 0;
          else if (d.data.legislator === legislator) return 0.7;
          else return 0.3;
        });

      d3
        .selectAll('.outerCrust')
        .attr('opacity', 0);

      d3
        .selectAll('.outerCrustText')
        .attr('opacity', 0);

      showCrustTooltip(client, legislator);
      showLegislatorTooltip(legislator);

    };

    const mouseOut = () => {

      d3
        .selectAll('.connections')
        .data(arcData.filter(it => it.data.legislator !== null))
        .attr('opacity', 0.1);

      d3
        .selectAll('.bubbles')
        .data(root.descendants())
        .attr('opacity', (d) => {
          if (d.data.party === undefined) return 0;
          else return 0.3;
        });

      d3
        .selectAll('.outerCrust')
        .attr('opacity', 1);

      d3
        .selectAll('.outerCrustText')
        .attr('opacity', 1);

      d3
        .selectAll('.industryLevelAnalysisTooltip')
        .remove();

    };

    // DRAW

    // CRUST
    svg
      .selectAll('crust')
      .data(arcData)
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('fill', d => {
        if (d.data.party === 'Democrat') return COLOR_DEMO;
        else if (d.data.party === 'Republican') return COLOR_REP;
        else if (d.data.party === 'Independent') return COLOR_INDEPENDENT;
        else return 'transparent';
      })
      .attr('opacity', 0.3)
      .attr('transform', `translate(${CANVAS_WIDTH / 2}, ${CANVAS_HEIGHT / 2})`)
      .on('mouseover', function (d) {
        if (d.data.party !== null) {
          d3.select(this).attr('opacity', 1);
          mouseOverCrust(d.data.client, d.data.legislator);
        }
      })
      .on('mouseout', function (d) {
        if (d.data.party !== null) {
          d3.select(this).attr('opacity', 0.3);
          mouseOut();
        }
      });

    // OUTER CRUST
    const outerArcData = [];
    let lastArcClient = '';

    for (const item of arcData) {

      if (item.data.client !== lastArcClient) {

        outerArcData.push(deepClone(item));
        lastArcClient = item.data.client;

      } else {

        outerArcData[outerArcData.length - 1].endAngle = item.endAngle;

      }

    }

    svg
      .selectAll('outerCrust')
      .data(outerArcData)
      .enter()
      .append('path')
      .attr('class', 'outerCrust')
      .attr('d', outerArc)
      .attr('fill', d => {
        if (d.data.party === 'Democrat' || d.data.party === 'Republican' || d.data.party === 'Independent') return 'black';
        else return 'transparent';
      })
      .attr('transform', `translate(${CANVAS_WIDTH / 2}, ${CANVAS_HEIGHT / 2})`)
      .attr('id', (d) => 'id_crust_' + d.data.client);

    // OUTER CRUST TEXT
    const arcNameData = {};
    lastArcClient = '';

    for (const item of arcData) {

      if (item.data.client !== null) {

        if (item.data.client !== lastArcClient) {

          arcNameData[item.data.client] = {
            startAngle: item.startAngle,
            endAngle: item.endAngle
          };

          lastArcClient = item.data.client;

        } else {

          arcNameData[item.data.client].endAngle = item.endAngle;

        }

      }

    }

    for (const [key, value] of Object.entries(arcNameData)) {

      if (value.endAngle - value.startAngle < 0.3) continue; // exclude too small clients

      svg
        .append('text')
        .style('font-size', 13)
        .style('text-anchor', 'middle')
        .attr('dy', '-10')
        .append('textPath')
        .attr('class', 'outerCrustText')
        .attr('xlink:href', '#id_crust_' + key)
        .attr('startOffset', '25%')
        .text(key);

    }

    // CONNECTIONS
    for (const item of arcData) {

      if (item.data.legislator === null) continue;

      svg
        .append('path')
        .datum(connection(item))
        .attr('class', 'connections')
        .attr('d', line)
        .attr('fill', () => {
          if (item.data.party === 'Democrat') return COLOR_DEMO;
          else if (item.data.party === 'Republican') return COLOR_REP;
          else if (item.data.party === 'Independent') return COLOR_INDEPENDENT;
        })
        .attr('opacity', 0.1);

    }

    // LEGISLATOR BUBBLES
    svg
      .selectAll('bubble')
      .data(root.descendants())
      .enter()
      .append('circle')
      .attr('class', 'bubbles')
      .attr('cx', (d) => d.x)
      .attr('cy', (d) => d.y)
      .attr('r', (d) => d.r)
      .attr('opacity', (d) => {
        if (d.data.party === undefined) return 0;
        else return 0.3;
      })
      .attr('fill', (d) => {
        if (d.data.party === 'Democrat') return COLOR_DEMO;
        else if (d.data.party === 'Republican') return COLOR_REP;
        else if (d.data.party === 'Independent') return COLOR_INDEPENDENT;
      })
      .attr('transform', `translate(${CANVAS_WIDTH / 2 - GRAPH_DIAMETER / 2 + BUBBLE_PADDING}, ${CANVAS_HEIGHT / 2 - GRAPH_DIAMETER / 2 + BUBBLE_PADDING})`)
      .on('mouseover', (d) => {
        if (d.data.party !== undefined) mouseOverLegislator(d.data.legislator);
      })
      .on('mouseout', (d) => {
        if (d.data.party !== undefined) mouseOut();
      });

  }

  loadOverview = () => {

    const compare = (a, b) => {

      if (a.amount < b.amount) return 1;
      else if (a.amount > b.amount) return -1;
      else return 0;

    };

    const total = this.state.data.total;
    const clientData = this.state.data.perClient;
    const legislatorData = this.state.data.perLegislator;

    const topClients = clientData.sort(compare).slice(0, 5);
    const topLegislators = legislatorData.sort(compare).slice(0, 5);

    this.setState({
      totalAmount: total,
      topClients: topClients.map((value, index, array) =>
        <span className="industryLevelAnalysisItems" key={index}>
          {index + 1}. {value.client}
          <br />
          ${formatMoney(value.amount)} ({(value.amount / total * 100).toFixed(1)}%)
        </span>
      ),
      topLegislators: topLegislators.map((value, index, array) =>
        <span className="industryLevelAnalysisItems" key={index}>
          {index + 1}. {value.legislator} ({value.party})
          <br />
          ${formatMoney(value.amount)} ({(value.amount / total * 100).toFixed(1)}%)
        </span>
      )
    });

  }

  render() {
    return (
      <div id="industryLevelAnalysisContainer">

        <div>
          <h2>Industry Level Analysis</h2>
        </div>

        <div id="industryLevelAnalysisContent">

          <div id="industryLevelAnalysisController">

            <Form.Group>
              <Form.Label>Year</Form.Label>
              <AutoSuggestion
                minInputLength={1}
                maxSuggestions={5}
                placeholder={"e.g. 2020"}
                client={this.props.client}
                clickSuggestion={this.yearSuggestions.clickSuggestion}
                processResponse={this.yearSuggestions.processResponse}
                getSuggestionString={this.yearSuggestions.getSuggestionString}
                getQuery={this.yearSuggestions.getQuery}

                type="number"
                parentInput={this.state.year}
                parentChangeInput={input => this.setState({ year: input })} />
            </Form.Group>

            <br />

            <Form.Group>
              <Form.Label>NAICS Code</Form.Label>
              <AutoSuggestion
                minInputLength={1}
                maxSuggestions={5}
                placeholder={"e.g. 511210 Software Publishers"}
                client={this.props.client}
                clickSuggestion={this.naicsSuggestions.clickSuggestion}
                processResponse={this.naicsSuggestions.processResponse}
                getSuggestionString={this.naicsSuggestions.getSuggestionString}
                getQuery={this.naicsSuggestions.getQuery}

                parentInput={this.state.naics}
                parentChangeInput={input => this.setState({ naics: input })} />
            </Form.Group>

            <br />

            <div>
              <Button id="industryLevelAnalysisSearch" type="submit" onClick={this.search}>Search <FontAwesomeIcon icon={faSearch} /></Button>
            </div>

            <div id="industryLevelAnalysisSocialButton">
              <SocialMedia />
            </div>

          </div>

          <div id="industryLevelAnalysisGraph">

            <div id="svgContainer" />

            {
              this.state.displayNaics === 'Example Industry' &&
              <div id="industryLevelAnalysisDescription">
                <div id="industryLevelAnalysisDescriptionTitle">How to read this visualization</div>
                <div>This visualization describes the distribution of lobbying activities of firms within the industry chosen by a researcher. Users can choose the Year and the industry code (NAICS Code). The arc of a circle corresponds to a firm and the length of the arc is proportional to the lobbying expenditure. The colored bubbles in the middle of the circle correspond to legislators (red for Republicans and blue for Democrats). The link between a firm and a legislator indicates that the former lobbied on the bill sponsored by the latter in the given year. The size of each bubble as well as the link width between firms and politicians are proportional to the "amount" that is related to the firm- and legislator-level activities. Specifically, we compute the "amount" by first identifying the lobbying expenditure reported in each lobbying report. We then divide the expenditure by the number of bills reported in the given report and aggregate the amount at the firm- and legislator-level. It is important to note that the "amount" is NOT an amount directly paid by the firm to the politician. On the right panel, we display the total amount of lobbying expenditures spent by the firms in the industry for the year (i.e., Total Amount), top five clients in terms of their lobbying expenditure (i.e., Top Clients), and the top legislators in terms of the amount of lobbying expenditures linked to the bills that they have introduced to the Congress (i.e., Top Legislators).</div>
              </div>
            }

          </div>

          <div id="industryLevelAnalysisOverView">

            <div id="industryLevelAnalysisTopNaics">
              <span>
                <span>{this.state.displayNaics}<br /></span>
                {
                  this.state.displayNaicsTitle !== '' &&
                  <span>{this.state.displayNaicsTitle}<br /></span>
                }
                <span>Year : {this.state.displayYear}</span>
              </span>
            </div>

            <div className="industryLevelAnalysisTopContent">

              <span className="industryLevelAnalysisTopTitle">Total Amount</span>

              <span className="industryLevelAnalysisItems">${formatMoney(this.state.totalAmount)}</span>

            </div>

            <div className="industryLevelAnalysisTopContent">

              <span className="industryLevelAnalysisTopTitle">Top Clients</span>

              {this.state.topClients}

            </div>

            <div className="industryLevelAnalysisTopContent">

              <span className="industryLevelAnalysisTopTitle">Top Legislators</span>

              {this.state.topLegislators}

            </div>

          </div>

        </div>

      </div>
    );
  }

}

export default withApolloClient(IndustryLevelAnalysis);
