import React, { Component } from 'react'
import PropTypes from 'prop-types'

import "./network.scss";

import { ascending, range } from "d3";
import Matrix from "matrix-d3-react" //https://github.com/harryli0088/matrix-d3-react
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import memoizeOne from 'memoize-one'
import MathJax from 'components/mathJax/MathJax'

import capitalize from "utils/capitalize/capitalize";
import processNetworkData from "./processNetworkData";


const MAX_HEADING_LENGTH = 12;
const RECT_LENGTH = 20;
const HEADING_LENGTH = 150;
export const NETWORK_EXPLANATION = <span>This visualization shows the relationship between politicians (Columns) and companies that interact with them (Rows). The &nbsp;&nbsp;<MathJax tex={'(i,j)'}/>th&nbsp;&nbsp; entry in the heat map is the number of times interest group &nbsp;&nbsp;<MathJax tex={'i'}/>&nbsp;&nbsp; lobbied on the bill that politician &nbsp;&nbsp;<MathJax tex={'j'}/>&nbsp;&nbsp; sponsored. The squares are colored linearly by the number of interactions from light blue (fewer interactions) to dark blue (higher interactions) and white (no interactions). You can sort by the names of the entities or by the number of interactions. Each entity is show with its name and the number of interactions in parentheses.</span>

export default class Network extends Component {
  constructor(props) {
    console.log("CONSTRUCTED")
    super(props);

    this.orderByOptions = ["name","count"]; //array of options that the user can sort the array by

    this.state = {
      orderBy: this.orderByOptions[0],
      togglesHeight: 100,
      width: 1000,
      height: 1000,
      mouseoverTooltip: null,
    }

    this.togglesRef = React.createRef()
    this.matrixContainerRef = React.createRef()
  }

  componentDidMount() {
    this.resize()
    window.addEventListener("resize", this.resize)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize)
  }

  componentDidUpdate(prevProps) {
    //sometimes the pageHeight changes without this component realizing
    //so we need this check here to make sure we're resizing properly
    if(prevProps.pageHeight !== this.props.pageHeight) { //if the page height has changed
      this.resize() //run a resize
    }
  }

  resize = e => {
    if(this.matrixContainerRef.current) {
      this.setState({
        width: this.matrixContainerRef.current.clientWidth,
        height: this.matrixContainerRef.current.clientHeight,
        togglesHeight: this.togglesRef.current.clientHeight,
      })
    }
  }

  buildMatrix = memoizeOne(processNetworkData)


  processData = memoizeOne(
    (networkData, width, height, togglesHeight) => {
      const {
        colorFunction,
        columns,
        matrix,
        maxContributionCount,
        rows,
      } = this.buildMatrix(networkData)

      //In order to dynamically filter out smaller elements if the screen is too small, we need to
      //1) Figure out how many rows we can fit on screen
      //2) Sort the data so that we can get the highest value elements and slice off the smaller value elements
      //3) Build filtered versions of the matrix, rows, and columns


      //1) get the maximum number of rows for the given height
      const maxNumberRows = Math.floor((height - togglesHeight - HEADING_LENGTH) / RECT_LENGTH)
      const maxNumberColumns = Math.floor((width - togglesHeight - HEADING_LENGTH) / RECT_LENGTH)
      console.log("width",width,"height",height)
      //2) for both rows and columns, sort by count, then slice off the elements we cannot fit
      const filteredOldRowIndicies = range(rows.length).sort(function(a, b) { return rows[b].count - rows[a].count; }).slice(0,maxNumberRows)
      const filteredOldColumnIndicies = range(columns.length).sort(function(a, b) { return columns[b].count - columns[a].count; }).slice(0,maxNumberColumns)

      //3) make new filtered data
      const filteredMatrix = []
      const filteredRows = []
      const filteredColumns = []
      filteredOldRowIndicies.forEach((oldRowIndex, filteredRowIndex) => { //for each old row
        let newRowCount = 0

        const filteredCells = [] //filtered cells for this row
        filteredOldColumnIndicies.forEach((oldColumnIndex, filteredColumnIndex) => { //for each old column
          const count = matrix[oldRowIndex][oldColumnIndex].z //get the original value for this row,column

          newRowCount += count

          filteredCells.push({ //push in the new element
            r: filteredRowIndex, //new row index
            c: filteredColumnIndex, //new column index
            z: count,
          })
        })
        filteredMatrix.push(filteredCells) //push in the new cells for this row

        filteredRows.push(rows[oldRowIndex]) //get the orignal row element
        filteredRows[filteredRowIndex].count = newRowCount
      })

      filteredOldColumnIndicies.forEach((oldColumnIndex, filteredColumnIndex) => { //for each old column
        let newColumnCount = 0
        filteredMatrix.forEach(filteredRow => {
          newColumnCount += filteredRow[filteredColumnIndex].z
        })

        filteredColumns.push(columns[oldColumnIndex]) //get the original column element
        filteredColumns[filteredColumnIndex].count = newColumnCount
      })



      //precompute the orders using d3
      const orders = {
        rows: {
          name: range(filteredRows.length).sort(function(a, b) { return ascending(filteredRows[a].name, filteredRows[b].name); }),
          count: range(filteredRows.length).sort(function(a, b) { return filteredRows[b].count - filteredRows[a].count; }),
        },
        columns: {
          name: range(filteredColumns.length).sort(function(a, b) { return ascending(filteredColumns[a].name, filteredColumns[b].name); }),
          count: range(filteredColumns.length).sort(function(a, b) { return filteredColumns[b].count - filteredColumns[a].count; })
        }
      }

      // // ********** this code is used to test that the filtering works properly
      // filteredMatrix.forEach((row, rowIndex) => {
      //   let rowCount = 0
      //
      //   row.forEach((column, columnIndex) => {
      //     rowCount += filteredMatrix[rowIndex][columnIndex].z
      //
      //
      //     const oldRowIndex = binarySearch(rows, filteredRows[rowIndex], comparator([{field:"node_id"}])) //search for the old row index
      //     const oldColumnIndex = binarySearch(columns, filteredColumns[columnIndex], comparator([{field:"node_id"}])) //search for the old column index
      //
      //     //if the z values don't match, there is something wrong
      //     if(matrix[oldRowIndex][oldColumnIndex].z !== filteredMatrix[rowIndex][columnIndex].z) {
      //       console.warn(
      //         "The Z values in the matrix are not the same",
      //         "matrix[oldRowIndex][oldColumnIndex].z",matrix[oldRowIndex][oldColumnIndex].z,
      //         "oldRowName",rows[oldRowIndex].name,
      //         "oldRowIndex",oldRowIndex,
      //         "oldColName",columns[oldColumnIndex].name,
      //         "oldColumnIndex",oldColumnIndex,
      //         "filteredMatrix[rowIndex][columnIndex].z",filteredMatrix[rowIndex][columnIndex].z,
      //         "rowName",filteredRows[rowIndex].name,
      //         "rowIndex",rowIndex,
      //         "columnName",filteredColumns[columnIndex].name,
      //         "columnIndex",columnIndex,
      //       )
      //     }
      //   })
      //
      //   if(rowCount !== filteredRows[rowIndex].count) {
      //     console.warn(
      //       "The row count value is not correct",
      //       "rowIndex",rowIndex,
      //       "rowCount",rowCount,
      //       "filteredRows[rowIndex].count",filteredRows[rowIndex].count,
      //     )
      //   }
      // })
      //
      //
      // filteredColumns.forEach((filteredColumn, filteredColumnIndex) => {
      //   let columnCount = 0
      //
      //   filteredMatrix.forEach(row => {
      //     columnCount += row[filteredColumnIndex].z
      //   })
      //
      //   if(columnCount !== filteredColumn.count) {
      //     console.warn(
      //       "The column count value is not correct",
      //       "filteredColumnIndex",filteredColumnIndex,
      //       "columnCount",columnCount,
      //       "filteredColumn.count",filteredColumn.count,
      //     )
      //   }
      // })
      //
      // // ********** end of test code


      return {
        colorFunction,
        columns: filteredColumns,
        data: filteredMatrix,
        maxContributionCount,
        orders,
        rows: filteredRows,
      };
    }
  )

  getLegend = (colorFunction, maxContributionCount) => {
    if(this.props.fullLegend) { //if full legend
      return (
        <React.Fragment>
          <span>0 Interactions </span>
          <div className="stretchGradientLegendElement legendElement" style={{
            background: "linear-gradient(90deg,"+colorFunction(0)+","+colorFunction(maxContributionCount)+")",
          }}></div>
          <span style={{float: "right"}}> {maxContributionCount} Interactions</span>
        </React.Fragment>
      )
    }

    return ( //return legend in parts
      <React.Fragment>
        <div className="noContributionsLegend">
          <span>No Interactions </span>
          <div className="legendElement" style={{
            width:"1em",
            background: colorFunction(0),
          }}></div>
        </div>

        <div className="partContributionsLegend">
          <span>1 Interaction </span>
          <div className="stretchGradientLegendElement legendElement" style={{
            background: "linear-gradient(90deg,"+colorFunction(1)+","+colorFunction(maxContributionCount)+")",
          }}></div>
          <span style={{float: "right"}}> {maxContributionCount} Interactions</span>
        </div>
      </React.Fragment>
    )
  }

  getDefaultExplanation = () => {
    if(this.props.requestStatus === "") {
      return (
        <div>
          <div>{NETWORK_EXPLANATION}</div>
          <div>In this example, Company 2 interacted with Politician D for a total of 18 times.</div>
        </div>
      )
    }
  }

  onMouseMoveContainer = (e) => {
    this.setState({
      mouseoverTooltip: {
        ...this.state.mouseoverTooltip,
        x: e.clientX,
        y: e.clientY,
      }
    })
  }

  onMouseOverHandler = (e, rowIndex, colIndex) => {
    this.setState({
      mouseoverTooltip: {
        colIndex,
        rowIndex,
        x: e.clientX,
        y: e.clientY,
      }
    })
  }

  onMouseOutContainer = () => this.setState({mouseoverTooltip: null})

  getTooltip = (data, rows, columns) => {
    const mouseoverTooltip = this.state.mouseoverTooltip

    if(mouseoverTooltip !== null) {
      const company = rows[mouseoverTooltip.rowIndex] //get the company object
      const politician = columns[mouseoverTooltip.colIndex] //get the politician object

      //try to get the cell count, else the company count, else the politician count
      const interactions = data?.[mouseoverTooltip.rowIndex]?.[mouseoverTooltip.colIndex]?.z || company?.count || politician?.count

      //if there is a company or politician, render the tooltip
      if(company || politician) {
        return (
          <Tooltip placement="top" style={{
            top: mouseoverTooltip.y,
            left: mouseoverTooltip.x,
          }}>
            {!isNaN(interactions) ? <div><b>Number of Interactions: </b> {interactions}</div> : null}
            <br/>
            {company ? <div><b>Company: </b> {company.name}</div> : null}
            {politician ? <div><b>Politician: </b> {politician.name}</div> : null}
          </Tooltip>
        )
      }
    }
  }


  render() {
    const {
      colorFunction,
      columns,
      data,
      maxContributionCount,
      orders,
      rows,
    } = this.processData(
      this.props.networkData,
      this.state.width,
      this.state.height,
      this.state.togglesHeight,
    );


    if(data.length > 0) {
      const defaultExplanationHeight = this.props.requestStatus==="" ? window.innerHeight/5 : 0

      return (
        <div className="networkContainer" ref={this.matrixContainerRef}>
          <div ref={this.togglesRef}>
            <div className="networkInfo">
              <div>
                <strong>Currently showing the top {data.length} companies and top {data[0].length} politicians</strong>
              </div>

              <br/>

              <div className="networkToggles">
                <Dropdown>
                  <Dropdown.Toggle variant="secondary">
                    Order By: <strong>{capitalize(this.state.orderBy)}</strong>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {this.orderByOptions.map((o,i) =>
                      <Dropdown.Item key={i} onClick={e => this.setState({orderBy: o})}>
                        {capitalize(o)}
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>

                &nbsp;

                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip>
                      {NETWORK_EXPLANATION}
                    </Tooltip>
                  }>
                  <div>
                    <Button variant="warning" style={{color: "white"}}>Methodology</Button>
                  </div>
                </OverlayTrigger>
              </div>
            </div>

            <div className="networkLegend">
              {this.getLegend(colorFunction, maxContributionCount)}
            </div>
          </div>


          <div style={{height: "calc(100% - " + this.state.togglesHeight + "px - " + defaultExplanationHeight + "px)"}} onMouseMove={this.onMouseMoveContainer} onMouseOut={this.onMouseOutContainer}>
            <Matrix
              data={data}
              rows={rows}
              columns={columns}
              colorFunction={colorFunction}
              orders={orders}
              orderBy={this.state.orderBy}

              height={this.state.height - this.state.togglesHeight - defaultExplanationHeight}
              formatRowHeading={function(text, count) {
                const countText = count>0 ? " ("+count+")" : "";

                let shortenedText = text;
                if(shortenedText.length > MAX_HEADING_LENGTH) {
                  shortenedText = shortenedText.slice(0, MAX_HEADING_LENGTH) + "...";
                }

                return [shortenedText, countText];
              }}
              formatColHeading={function(text, count) {
                const countText = count>0 ? "("+count+") " : "";

                let shortenedText = text;
                if(shortenedText.length > MAX_HEADING_LENGTH) {
                  shortenedText = shortenedText.slice(0, MAX_HEADING_LENGTH) + "...";
                }

                return [shortenedText, countText];
              }}
              gridLinesColor="gray"
              minRectSize={RECT_LENGTH}
              onMouseOverHandler={this.onMouseOverHandler}
            />
          </div>

          {this.getTooltip(data, rows, columns)}

          {this.getDefaultExplanation()}
        </div>
      );
    }

    else if(this.props.requestStatus === "loading") {
      return "Loading...";
    }
    else if(this.props.requestStatus === "error") {
      return "There was an error getting the data. Please try again later.";
    }
    else if(this.props.requestStatus === "done") {
      return "There is no data from the query for this visualization";
    }
    else if(this.props.requestStatus === "") {
      return "Please specify a year and issue in the form to get data for this visualization";
    }

    return "";
  }
}

Network.propTypes = {
  fullLegend: PropTypes.bool,
  networkData: PropTypes.object.isRequired,
  pageHeight: PropTypes.number.isRequired,
  requestStatus: PropTypes.string.isRequired,
};
